import { Link } from "gatsby";
import React from "react";
import bikes from "../images/bikes.jpg";
import A from "./A";
import Page from "./Page";

export default function IndexPage() {
  return (
    <Page title="Matt Jesuele">
      <h1 className="text-3xl">Hi. I'm Matt.</h1>
    
    </Page>
  );
}
